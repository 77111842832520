/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard/Image";

// HelpCenter page components
import ListItem from "pages/Support/HelpCenter/components/ListItem";

// Images
import bgImage1 from "assets/images/wtp/ss/H2SettingsLight.png";
import bgImage2 from "assets/images/wtp/ss/H2AccSett.png";
import bgImage3 from "assets/images/wtp/ss/H2DelAcc.png";

function Features() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center" sx={{ mt: 6 }}>
          <Grid item xs={12} md={4} sx={{ ml: "auto" }}>
            <DefaultBackgroundCard
              image={bgImage1}
              label="website visitors"
              title="The Best Productivity Apps on Market"
              description="As we live, our hearts turn colder. Cause pain is what we go through as we become older. We get insulted by others, lose trust for those others."
              action={{
                type: "internal",
                route: "/pages/support/help-center",
                label: "get started",
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
            <ListItem title="1. ">
              {`From main side menu, Go to 'Settings'.`}      
            </ListItem>
            <ListItem title="2. ">
              {`Under Settings menu Select 'Update Account Settings'`}
            </ListItem>
          </Grid>
        </Grid>
        <Divider sx={{ my: { xs: 2, sm: 8 }, mx: 12 }} />
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <ListItem title="3. ">
              {`On the Account Settings page, press 'Delete Account'`}.
            </ListItem>
            <ListItem title="4. ">
              {`When you're ready to delete select 'BURN IT'.`}      
            </ListItem>
            <ListItem title="Note ">
              This action is irreversible.
            </ListItem>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
            <DefaultBackgroundCard
              image={bgImage2}
              label="social activities"
              title="Working on Wallstreet is Not So Easy"
              description="There’s nothing I really wanted to do in life that I wasn’t able to get good at. I’m not really specifically talented at anything except for the ability to learn."
              action={{
                type: "internal",
                route: "/pages/support/help-center",
                label: "get started",
              }}
            />
          </Grid>
        </Grid>
        {/* <Divider sx={{ my: { xs: 2, sm: 8 }, mx: 12 }} />
        <Grid container spacing={3} alignItems="center" sx={{ mt: 6 }}>
          <Grid item xs={12} md={4} sx={{ ml: "auto" }}>
            <DefaultBackgroundCard
              image={bgImage3}
              label="website visitors"
              title="The Best Productivity Apps on Market"
              description="As we live, our hearts turn colder. Cause pain is what we go through as we become older. We get insulted by others, lose trust for those others."
              action={{
                type: "internal",
                route: "/pages/support/help-center",
                label: "get started",
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
            <ListItem title="4. ">
              {`When you're ready to delete select 'BURN IT'.`}      
            </ListItem>
            <ListItem title="Note ">
              This action is irreversible.
            </ListItem>
          </Grid>
        </Grid> */}
      </Container>
    </MKBox>
  );
}

export default Features;
