/* eslint-disable prettier/prettier */
/* eslint-disable arrow-body-style */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable no-plusplus */

import { useState, useEffect, useRef } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes_wtp";
import footerRoutes from "footer.routes";

import moment from 'moment';

// Image
// import bgImage from "assets/images/illustrations/illustration-reset.jpg";
// import bgImage from "assets/images/wtp/pexels-koolshooters-7142993.jpg";

import bgImage1 from "assets/images/wtp/pexels-koolshooters-7142993.jpg";
import bgImage2 from "assets/images/wtp/pexels-koolshooters-7142962.jpg";
import bgImage3 from "assets/images/wtp/pexels-koolshooters-7142958.jpg";
import bgImage4 from "assets/images/wtp/pexels-wendy-wei-1190297.jpg";
import bgImage5 from "assets/images/wtp/pexels-rodnae-productions-6224736.jpg";
import bgImage6 from "assets/images/wtp/pexels-rodnae-productions-7683885.jpg";
import bgImage7 from "assets/images/wtp/pexels-giorgio-paradisi-2705089.jpg";
import bgImage8 from "assets/images/wtp/pexels-wendy-wei-3633312.jpg";

const getRandyInt = (min, max)=> {
  return (Math.floor(Math.random() * (max - min)) + min);
}
const rand = `${getRandyInt(0,7)}`
console.log("RANDOM #", rand)

const bgRay = [bgImage1,bgImage2,bgImage3,bgImage4,bgImage5,bgImage6,bgImage7,bgImage8]

const bgImage = bgRay[rand] 


function ContactUs() {

const [email, setEmail] = useState("")
const [message, setMessage] = useState("")
const [name, setName] = useState("")
const [alertOpen, setAlertOpen] = useState(false)
const [alertMessage, setAlertMessage] = useState("")
const [alertColor, setAlertColor] = useState("success")
const [alertCancel, setAlertCancel] = useState(false)
const [alertCancelMessage, setAlertCancelMessage] = useState("")

const useTime = useRef('')
const cancelAlert = useRef(false)


const saveMail = localStorage.getItem("contact_us_email")
  const phun = async() => {
    const getEmail = localStorage.getItem("contact_us_email")
    if(getEmail !== undefined && getEmail !== null){
      const imail = JSON.parse(getEmail)
      setEmail(imail)
      console.log("Saved Email Found... Coming from About Us ..... EMAIL: ", imail)
    }else{
      console.log("No saved email found.....")
    }
}
useEffect(()=>{
phun();

},[saveMail])

const wait = (timeout) => {
  return new Promise (resolve => {
    setTimeout(resolve, timeout);
  })
}

const onEmailChange = (val) => {
  console.log("Email Added: ", val)
  setEmail(val)
}
const onMessageChange = (val) => {
  console.log("Message Added: ", val)
  setMessage(val)
}
const onNameChange = (val) => {
  console.log("Name Added: ", val)
  setName(val)
}

const clearForm = async() => {
  useTime.current = moment().add(10, 'seconds').format()
  setAlertOpen(true)
  setAlertColor("success")
  setAlertMessage("Email sent! You will be contacted shortly.")
  if(cancelAlert.current !== false){
    const time = 10000
    const secs = [0,1,2,3,4,5,6,7,8,9]
    setAlertCancelMessage(`Page will refresh in ... 10 Sec`)
    // setAlertCancel(true) 
    wait(1000).then(()=>{
      setAlertCancelMessage(`Page will refresh in 9 seconds... Close to cancel refresh`)})
      wait(2000).then(()=>{
        setAlertCancelMessage(`Page will refresh in 8 seconds... Close to cancel refresh`)})
        wait(3000).then(()=>{
          setAlertCancelMessage(`Page will refresh in 7 seconds... Close to cancel refresh`)})
          wait(4000).then(()=>{
            setAlertCancelMessage(`Page will refresh in 6 seconds... Close to cancel refresh`)})
            wait(5000).then(()=>{
              setAlertCancelMessage(`Page will refresh in ... 5 seconds... Close to cancel refresh`)})
              wait(6000).then(()=>{
                setAlertCancelMessage(`Page will refresh in ... 4 seconds... Close to cancel refresh`)})
                wait(7000).then(()=>{
                  setAlertCancelMessage(`Page will refresh in ... 3 seconds... Close to cancel refresh`)})
                  wait(8000).then(()=>{
                    setAlertCancelMessage(`Page will refresh in ... 2 seconds... Close to cancel refresh`)})
                    wait(9000).then(()=>{
                      console.log("1 sec.. SHOULD CANCEL ", cancelAlert.current)   
                      setAlertCancelMessage(`Page will refresh in ... 1 seconds... Close to cancel refresh`)})
                      if(cancelAlert.current!==false){   
                        console.log("CANCEL ", cancelAlert.current)     
                        wait(time).then(()=>{
                          console.log("TIME : ", time)
                         localStorage.removeItem("contact_us_email")
                          setEmail("")
                          setMessage("")
                          setName("")
                          setAlertOpen(false)
                          if(cancelAlert.current===true){   
                            console.log("CANCEL ", cancelAlert.current)
                            window.open("/pages/support/contact-us", "_self")
                          }
                      })

                  }  
        
        }
      }


useEffect(()=>{
  if(alertCancel=== true){
    cancelAlert.current= true
    clearForm()
  }
  if(cancelAlert.current === false){
    console.log("ALERT REF ", 
    cancelAlert.current)
    // cancelAlert.current= false
  }
  },[alertCancel,cancelAlert])
  

const makeContact = async(_email, _message, _name) => {
  if(email === ""){
    console.log("Missing Email Address")
    // alert("Missing Email Address")
    setAlertOpen(true)
    setAlertMessage(`Missing Email Address`)
    setAlertColor("error")
    wait(10000).then(()=>{
      setAlertOpen(false)
    })
  }else{
   if(message === ""){
      console.log("Missing Message")
      // alert("Missing Email Body")
      setAlertOpen(true)
      setAlertMessage(`Missing Email Body`)
      setAlertColor("error")
      wait(10000).then(()=>{
        setAlertOpen(false)
      })
    }else{
      try{
        console.log("MAKING CONTACT! ................")
        const resp = await fetch('https://us-central1-wtp-club.cloudfunctions.net/makecontact', {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({'email': _email,'message': _message, "name": _name})
        })
        const resJ = resp
        const resT = await resp.text()

        console.log("CONTACT MADE?", resJ)
        console.log("RES TXT?", resT)
        // if(resp !== undefined && resp !== null){
        // }
          setAlertOpen(true)
          setAlertMessage("Email sent! You will be contacted shortly.")
          setAlertColor("success")
          wait(2000).then(()=>{
          setEmail("")
          setMessage("")
          setName("")
          setAlertCancel(true)
            })
          }catch(error){
          console.log("CATCH ERROR ~ makeContact(); ContactUs_pg... Error ~ ", error)
            setAlertOpen(true)
            setAlertMessage(`Error sending message: ${error.message}`)
            setAlertColor("error")
          wait(10000).then(()=>{
            setAlertOpen(false)
          })
        }
      }
    }
  }




  // const AlertShow = () => 
  // <>
  // <MKBox>
  //   <Collapse in={alertOpen}>
  //     <Alert
  //     action={
  //       <IconButton
  //       aria-label="close"
  //       color="inherit"
  //       size="small"
  //       onClick={()=>{setAlertOpen(false)}}
  //       >
  //         <CloseIcon fontSize='inherit'/>
  //       </IconButton>
  //     }
  //     sx={{mb:2}}
  //     >
  //       {alertMessage}
  //     </Alert>
  //   </Collapse>
  // </MKBox> </>

  

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          // action={{
          //   type: "external",
          //   route: "https://www.creative-tim.com/product/material-kit-pro-react",
          //   label: "buy now",
          //   color: "info",
          // }}
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
          position="fixed" 
          // position="absolute"
          marginLeft={7}
          top={90}
          >
          <Collapse in={alertOpen}>
            <Alert
            color={alertColor}
            action={
              <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={()=>{
                setAlertOpen(false)
              }}
              >
                <CloseIcon fontSize='inherit'/>
              </IconButton>
            }
            sx={{mb:2}}
            >
              {alertMessage}
            </Alert>
          </Collapse>
        </MKBox>
          <MKBox
          position="fixed" 
          // position="absolute"
          marginLeft={7}
          top={195}
          >
          <Collapse in={alertCancel}>
            <Alert
            color="warning"
            action={
              <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={()=>{
                setAlertCancel(false)
                cancelAlert.current= false
              }}
              >
                <CloseIcon fontSize='inherit'/>
              </IconButton>
            }
            sx={{mb:2}}
            >
              {alertCancelMessage}
            </Alert>
          </Collapse>
        </MKBox>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            // backgroundPosition: "center",
          }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Contact us
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                For further questions please contact us.
              </MKTypography>
              <MKBox width="100%" component="form" method="post" autocomplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      variant="standard"
                      label="Full Name"
                      defaultValue={name}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(event) => {
                        onNameChange(event.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="email"
                      variant="standard"
                      label="Email"
                      defaultValue={email}
                      value={email}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(event) => {
                        onEmailChange(event.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label="How can we help you?"
                      defaultValue={message}
                      placeholder="Type here"
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={10}
                      onChange={(event) => {
                        onMessageChange(event.target.value)
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton tvariant="gradient" color="info"
                  onClick={()=> {
                    makeContact(email, message, name)
                    // console.log("CONTACT BUTTON PRESSED....")
                    // clearForm()
                      // setAlertCancel(true)
                  }}
                  >
                    Send Message
                  </MKButton>
                  {/* <MKButton type="submit" variant="gradient" color="info"
                  onClick={()=> {
                    console.log("CONTACT BUTTON PRESSED....")
                  }}
                  >
                    Send
                  </MKButton> */}
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
