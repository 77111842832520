/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// Material Kit 2 PRO React routes
import routes from "routes";
// import routes from "routes_wtp";


import { initializeApp } from 'firebase/app';
import AdsTxt from "./app-ads"

const config = {
  apiKey: "AIzaSyCQq7MB67uJLkQWINOZ6Nt_SP8Zs2C0TTs",
  authDomain: "wtp-club.firebaseapp.com",
  databaseURL: "https://wtp-club.firebaseio.com",
  projectId: "wtp-club",
  storageBucket: "wtp-club.appspot.com",
};

const firebaseApp = initializeApp(config)


export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/landing" element={<Presentation />} />
        <Route path="*" element={<Navigate to="/landing" />} />
        <Route path="/app-ads.txt" element={<AdsTxt />} />
      </Routes>
    </ThemeProvider>
  );
}
