/* eslint-disable prettier/prettier */
/* eslint-disable arrow-body-style */
/* eslint-disable no-irregular-whitespace */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
// import routes from "routes";
import routes from "routes_wtp";
import footerRoutes from "footer.routes";

function Privacy() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        // action={{
        //   type: "external",
        //   route: "https://www.creative-tim.com/product/material-kit-pro-react",
        //   label: "buy now",
        //   color: "dark",
        // }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Terms & Conditions
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Last modified: March 15 2019
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Introduction
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  Please read these Terms and Conditions (“Terms”, “Terms and Conditions”) carefully before using the www.wtpa.club website / #WTP@ mobile app (the “Service”) operated by The E.S.T.E Group (“us”, “we”, or “our”).

                  Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. 
                  These Terms apply to all visitors, users and others who wish to access or use the Service.
                    <br />
                    <br />
                    By accessing or using the Service you agree to be bound by these Terms. 
                    If you disagree with any part of the terms then you do not have permission to access the Service.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                    Accounts
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  When you create an account with us, you guarantee that you are above the age of 18, 
                  and that the information you provide us is accurate, complete, and current at all times. 
                  Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service.
                  You are responsible for maintaining the confidentiality of your account and password, 
                  including but not limited to the restriction of access to your computer and/or account. 
                  <br/><br/>
                  You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, 
                  whether your password is with our Service or a third-party service. 
                  You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                  Intellectual Property
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  The Service and its original content, features and functionality are and will remain the exclusive property of The E.S.T.E Group and its licensors. 
                  The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. 
                  Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of The E.S.T.E Group.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                  Links To Other Web Sites
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  Our Service may contain links to third party web sites or services that are not owned or controlled by The E.S.T.E Group
                  The E.S.T.E Group has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. 
                  We do not warrant the offerings of any of these entities/individuals or their websites.
                  <br/><br/>
                  You acknowledge and agree that The E.S.T.E Group shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third party web sites or services.
                  We strongly advise you to read the terms and conditions and privacy policies of any third party web sites or services that you visit.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                  Termination
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  We may terminate or suspend your account and bar access to the Service immediately, 
                  without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.
                  If you wish to terminate your account, you may simply discontinue using the Service.
                  All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                  MISUSE
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  You are NOT allowed to use this app/software/website for any other use other than what it was intended for (Post and find parties/events in your area). 
                  We have a ZERO tolerance policy for objectionable content and abusive users.
                  Reported objectionable content will be flagged and you are required to remove it within 12 hours of being flagged or else you will be subjected to termination. 
                  Abusive users will be terminated immediately.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                  Governing Law
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  These Terms shall be governed and construed in accordance with the laws of Illinois, United States, without regard to its conflict of law provisions.
                  Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. 
                  If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. 
                  These Terms in addition to the EULA constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                  Changes
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  We reserve the right, at our sole discretion, to modify or replace these Terms at any time. 
                  If a revision is material we will provide at least 30 days notice prior to any new terms taking effect. 
                  What constitutes a material change will be determined at our sole discretion.
                  By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. 
                  If you do not agree to the new terms, you are no longer authorized to use the Service.
                  </MKTypography>
                  
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Contact Us
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  If you have any questions about these Terms, please contact us
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;
