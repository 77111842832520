/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */

import { useState, useEffect, useRef } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

// Images
import macbook from "assets/images/macbook.png";
import _ook from "assets/images/wtp/ss/v_e.png";
import _lyn from "assets/images/wtp/ss/sem_d.png";

function Newsletter() {
  const [email, setEmail] = useState("")
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertColor, setAlertColor] = useState("success")
  const [alertCancel, setAlertCancel] = useState(false)
  const [alertCancelMessage, setAlertCancelMessage] = useState("")
  
  const useTime = useRef('')
  const cancelAlert = useRef(false)

  const removeEmail = () => {
      localStorage.removeItem("contact_us_email")
      console.log("Saved Email Removed...")
    }
  useEffect(()=>{
    if(email === ""){
      removeEmail()
    }
  },[email])


  const wait = (timeout) => {
    return new Promise (resolve => {
      setTimeout(resolve, timeout);
    })
  }

  const onEmailChange = (val) => {
    console.log("Email Added: ", val)
    setEmail(val)
  }

  const goToForm = async() => {
    const save = await localStorage.setItem("contact_us_email",JSON.stringify(email))
    window.open("/pages/support/contact-us", "_self")
    
    }
  
  const onContactPressed = () => {
    if(email === ""){
      setAlertOpen(true)
      setAlertMessage(`Missing Email Address`)
      setAlertColor("error")
      wait(10000).then(()=>{
        setAlertOpen(false)
      })
    }else{
      goToForm()
    }
  }
  
  return (
    <MKBox component="section" pt={6} my={6}>
    <MKBox
    position="fixed" 
    // position="absolute"
    marginLeft={7}
    bottom={50}
    >
    <Collapse in={alertOpen}>
      <Alert
      color={alertColor}
      action={
        <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={()=>{
          setAlertOpen(false)
        }}
        >
          <CloseIcon fontSize='inherit'/>
        </IconButton>
      }
      sx={{mb:2}}
      >
        {alertMessage}
      </Alert>
    </Collapse>
  </MKBox>
    <MKBox
    position="fixed" 
    // position="absolute"
    marginLeft={7}
    top={195}
    >
    <Collapse in={alertCancel}>
      <Alert
      color="warning"
      action={
        <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={()=>{
          setAlertCancel(false)
          cancelAlert.current= false
        }}
        >
          <CloseIcon fontSize='inherit'/>
        </IconButton>
      }
      sx={{mb:2}}
      >
        {alertCancelMessage}
      </Alert>
    </Collapse>
  </MKBox>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={_lyn} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={_ook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
          <Grid item  md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">Do you want to know more?</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              Contact us
            </MKTypography>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <MKInput 
                type="email" 
                label="Email Address" 
                fullWidth
                defaultValue={email}
                onChange={(event) => {
                  onEmailChange(event.target.value)
                }} />
              </Grid>
              <Grid item xs={4}>
                <MKButton
                onClick={()=>{
                  onContactPressed()
                }}
                variant="gradient" color="info" sx={{ height: "100%" }}>
                  Contact
                </MKButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Newsletter;
