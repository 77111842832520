/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

function SocialAnalytics() {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          md={6}
          lg={5}
          sx={{ mx: "auto", pb: 3, textAlign: "center" }}
        >
          <MKTypography variant="h4" color="info" textGradient>
            <>
            Help Center 
           <br/>
            </>
          </MKTypography>
          <MKTypography variant="h2" my={1}>
           <>
           #WheresThePartyAt (#WTP@) 
            <br/>
            </>            
          </MKTypography>
          <MKTypography variant="body1" color="text">
           <>
           <br/>
           Having issues with the app?
           <br/>
            
            </>
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              icon="groups"
              title="FAQ"
              description="Covers common 'how to' questions that have been asked in the past."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              icon="support_agent"
              title="Contact Support"
              description="Can't find your issue? Contact us and we'll be happy to assist you."
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ mx: "auto" }}>
            <DefaultInfoCard
              icon="update"
              title="Request Feature"
              description="If you think the app is missing something, let us know and if your feature makes sense, we'll implement it."
              direction="center"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default SocialAnalytics;
