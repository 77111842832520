/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
// @mui material components
import { useRef,useEffect,useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

function AppAds() {


    const download = (filename, text) => {
        const element = document.createElement('a')
        element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`)
        element.setAttribute('download', filename)

        element.style.display = 'none'
        document.body.appendChild(element)

        element.click()

        document.body.removeChild(element)
    }
    useEffect(()=>{
        const filename = "app-ads.txt"
        const text = "google.com, pub-9503219970320118, DIRECT, f08c47fec0942fa0"
        download(filename, text)
    })


    return (
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h3"
              color="black"
              mt={6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
            google.com, pub-9503219970320118, DIRECT, f08c47fec0942fa0
            </MKTypography>
            </Grid>
            </Container>
)
}

export default AppAds;