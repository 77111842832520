/* eslint-disable prettier/prettier */
/* eslint-disable arrow-body-style */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable no-plusplus */

import { useState, useEffect, useRef } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes_wtp";
import footerRoutes from "footer.routes";

import moment from 'moment';


function ContactUs() {

  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [name, setName] = useState("")
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertColor, setAlertColor] = useState("success")
  const [alertCancel, setAlertCancel] = useState(false)
  const [alertCancelMessage, setAlertCancelMessage] = useState("")
  
  const useTime = useRef('')
  const cancelAlert = useRef(false)
  
  
  const wait = (timeout) => {
    return new Promise (resolve => {
      setTimeout(resolve, timeout);
    })
  }
  
  const onEmailChange = (val) => {
    console.log("Email Added: ", val)
    setEmail(val)
  }
  const onMessageChange = (val) => {
    console.log("Message Added: ", val)
    setMessage(val)
  }
  const onNameChange = (val) => {
    console.log("Name Added: ", val)
    setName(val)
  }
  
  const clearForm = () => {
    useTime.current = moment().add(10, 'seconds').format()
    setAlertOpen(true)
    setAlertColor("success")
    setAlertMessage("Email sent! You will be contacted shortly.")
    if(cancelAlert.current !== false){
      const time = 10000
      const secs = [0,1,2,3,4,5,6,7,8,9]
      setAlertCancelMessage(`Page will refresh in ... 10 Sec`)
      // setAlertCancel(true) 
      wait(1000).then(()=>{
        setAlertCancelMessage(`Page will refresh in 9 seconds... Close to cancel refresh`)})
        wait(2000).then(()=>{
          setAlertCancelMessage(`Page will refresh in 8 seconds... Close to cancel refresh`)})
          wait(3000).then(()=>{
            setAlertCancelMessage(`Page will refresh in 7 seconds... Close to cancel refresh`)})
            wait(4000).then(()=>{
              setAlertCancelMessage(`Page will refresh in 6 seconds... Close to cancel refresh`)})
              wait(5000).then(()=>{
                setAlertCancelMessage(`Page will refresh in ... 5 seconds... Close to cancel refresh`)})
                wait(6000).then(()=>{
                  setAlertCancelMessage(`Page will refresh in ... 4 seconds... Close to cancel refresh`)})
                  wait(7000).then(()=>{
                    setAlertCancelMessage(`Page will refresh in ... 3 seconds... Close to cancel refresh`)})
                    wait(8000).then(()=>{
                      setAlertCancelMessage(`Page will refresh in ... 2 seconds... Close to cancel refresh`)})
                      wait(9000).then(()=>{
                        console.log("1 sec.. SHOULD CANCEL ", cancelAlert.current)   
                        setAlertCancelMessage(`Page will refresh in ... 1 seconds... Close to cancel refresh`)})
                        if(cancelAlert.current!==false){   
                          console.log("CANCEL ", cancelAlert.current)     
                          wait(time).then(()=>{
                            console.log("TIME : ", time)
                            setEmail("")
                            setMessage("")
                            setName("")
                            setAlertOpen(false)
                            if(cancelAlert.current===true){   
                              console.log("CANCEL ", cancelAlert.current)
                              window.open("/pages/support/help-center", "_self")
                            }
                        })
  
                    }  
          
          }
        }
  
  
  useEffect(()=>{
    if(alertCancel=== true){
      cancelAlert.current= true
      clearForm()
    }
    if(cancelAlert.current === false){
      console.log("ALERT REF ", 
      cancelAlert.current)
      // cancelAlert.current= false
    }
    },[alertCancel,cancelAlert])
    
  
  const makeContact = async(_email, _message, _name) => {
    if(email === ""){
      console.log("Missing Email Address")
      // alert("Missing Email Address")
      setAlertOpen(true)
      setAlertMessage(`Missing Email Address`)
      setAlertColor("error")
      wait(10000).then(()=>{
        setAlertOpen(false)
      })
    }else{
     if(message === ""){
        console.log("Missing Message")
        // alert("Missing Email Body")
        setAlertOpen(true)
        setAlertMessage(`Missing Email Body`)
        setAlertColor("error")
        wait(10000).then(()=>{
          setAlertOpen(false)
        })
      }else{
        try{
          console.log("MAKING CONTACT! ................")
          const resp = await fetch('https://us-central1-wtp-club.cloudfunctions.net/makecontact', {
            method: 'POST',
            mode: 'no-cors',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({'email': _email,'message': _message, "name": _name})
          })
          const resJ = resp
          const resT = await resp.text()
  
          console.log("CONTACT MADE?", resJ)
          console.log("RES TXT?", resT)
          // if(resp !== undefined && resp !== null){
          // }
            setAlertOpen(true)
            setAlertMessage("Email sent! You will be contacted shortly.")
            setAlertColor("success")
            wait(2000).then(()=>{
            setEmail("")
            setMessage("")
            setName("")
            setAlertCancel(true)
              })
            }catch(error){
            console.log("CATCH ERROR ~ makeContact(); ContactUs_pg... Error ~ ", error)
              setAlertOpen(true)
              setAlertMessage(`Error sending message: ${error.message}`)
              setAlertColor("error")
            wait(10000).then(()=>{
              setAlertOpen(false)
            })
          }
        }
      }
    }
  
  
  return (
    <MKBox component="section" py={12}>
    <MKBox
    position="fixed" 
    // position="absolute"
    marginLeft={7}
    top={90}
    >
    <Collapse in={alertOpen}>
      <Alert
      color={alertColor}
      action={
        <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={()=>{
          setAlertOpen(false)
        }}
        >
          <CloseIcon fontSize='inherit'/>
        </IconButton>
      }
      sx={{mb:2}}
      >
        {alertMessage}
      </Alert>
    </Collapse>
  </MKBox>
    <MKBox
    position="fixed" 
    // position="absolute"
    marginLeft={7}
    top={195}
    >
    <Collapse in={alertCancel}>
      <Alert
      color="warning"
      action={
        <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={()=>{
          setAlertCancel(false)
          cancelAlert.current= false
        }}
        >
          <CloseIcon fontSize='inherit'/>
        </IconButton>
      }
      sx={{mb:2}}
      >
        {alertCancelMessage}
      </Alert>
    </Collapse>
  </MKBox>
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          xs={10}
          lg={6}
          sx={{ mx: "auto", mb: { xs: 0, md: 6 }, textAlign: "center" }}
        >
          <MKTypography variant="h3" mb={1}>
            {`Can't find what you're looking for?`}
          </MKTypography>
          <MKTypography variant="body2" color="text">
            If you have further questions, please contact us.
          </MKTypography>
        </Grid>
        <Grid container item xs={12} lg={8} sx={{ mx: "auto" }}>
          <MKBox width="100%" component="form" method="post" autocomplete="off">
            <MKBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MKInput
                    variant="standard"
                    label="Full Name"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    defaultValue={name}
                    onChange={(event) => {
                      onNameChange(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKInput
                    type="email"
                    variant="standard"
                    label="Email"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    defaultValue={email}
                    onChange={(event) => {
                      onEmailChange(event.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    variant="standard"
                    label="How can we help you?"
                    placeholder="Type here"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    fullWidth
                    rows={10}
                    defaultValue={message}
                    onChange={(event) => {
                      onMessageChange(event.target.value)
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item justifyContent="center" xs={12} my={6}>
                <MKButton 
                  onClick={()=> {
                    makeContact(email, message, name)
                    // console.log("CONTACT BUTTON PRESSED....")
                    // clearForm()
                      // setAlertCancel(true)
                  }}
                  // type="submit" 
                  variant="gradient" color="dark">
                  Send Message
                </MKButton>
              </Grid>
            </MKBox>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ContactUs;
