/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";

function AuthPages() {
  // pages images
  const img1 = "https://firebasestorage.googleapis.com/v0/b/wtp-club.appspot.com/o/app_images%2Fdroid_login.png?alt=media&token=2a1185fc-4b91-49a5-a1dd-ed4dbac3b84a";
  const img2 = "https://firebasestorage.googleapis.com/v0/b/wtp-club.appspot.com/o/app_images%2Fios_login.png?alt=media&token=3988466a-1c2f-47a2-a06c-ec5af713dbce";
  const img3 = "https://firebasestorage.googleapis.com/v0/b/wtp-club.appspot.com/o/app_images%2Fdroid_signup.png?alt=media&token=e80d54fb-e136-4b87-a27b-fab0bf5750a7";
  const img4 = "https://firebasestorage.googleapis.com/v0/b/wtp-club.appspot.com/o/app_images%2Fios_signup.png?alt=media&token=f4dd1243-fd2c-44b4-9bdd-b4a5f18b13fc";
  // const img1 =
  //   "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/account/sign-up-cover.jpg";
  // const img2 =
  //   "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/account/reset-cover.jpg";
  // const img3 =
  //   "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/account/signin-basic.jpg";


  return (
    <MKBox position="relative" variant="gradient" bgColor="dark" mt={{ xs: 0, lg: 12 }} mx={-2}>
      <MKBox
        component="img"
        src={bgPattern}
        alt="background-pattern"
        position="absolute"
        top={0}
        left={0}
        width={{ xs: "auto", lg: "100%" }}
        height={{ xs: "100%", lg: "auto" }}
        opacity={0.6}
      />
      <Container>
        <Grid container alignItems="center">
          <Grid
            item
            xs={12}
            lg={4}
            sx={{ my: "auto", py: 6, pr: { xs: 3, lg: 2 }, pl: { xs: 3, lg: 0 } }}
          >
            <MKTypography variant="h2" color="white" mb={3}>
              How to get the app
            </MKTypography>
            <MKTypography variant="body2" color="white" mb={2} opacity={0.8}>
              The #WTP@ app is availible on the App Store and on the Play Store.
              Select your platform to download.
            </MKTypography>
            <MKBox display="flex" alignItems="center">
              <MKButton 
              component="a"
              href="https://play.google.com/store/apps/details?id=club.wtpa.yooow"
              target="_blank"
              // onClick={()=>{}}
              variant="gradient" color="info" sx={{ mt: 3, mb: 2, mr: 1 }}>
                Android
              </MKButton>
              <MKButton 
              component="a"
              href="https://apps.apple.com/us/app/wtp/id1468943281"
              target="_blank"
              variant="gradient" color="error" sx={{ mt: 3, mb: 2 }}>
                IPhone
              </MKButton>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={8} sx={{ pl: { xs: 3, lg: 6 }, pr: { xs: 3, lg: 6 }, pb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={3} sx={{ mt: { xs: 0, lg: 6 } }}>
                {/* <Link to="/authentication/sign-up/cover">
                </Link> */}
                  <ExampleCard image={img1} display="grid" minHeight="max-content" p={0.5} />
              </Grid>
              <Grid item xs={12} lg={3} sx={{ mt: { xs: 0, lg: 6 } }}>
                {/* <Link to="/authentication/reset-password/cover">
                </Link> */}
                  <ExampleCard image={img2} display="grid" minHeight="max-content" p={0.5} />
              </Grid>
              <Grid item xs={12} lg={3} sx={{ mt: { xs: 0, lg: 6 } }}>
                {/* <Link to="/authentication/sign-in/basic">
                </Link> */}
                  <ExampleCard image={img3} display="grid" minHeight="max-content" p={0.5} />
              </Grid>
              <Grid item xs={12} lg={3} sx={{ mt: { xs: 0, lg: 6 } }}>
                {/* <Link to="/authentication/sign-in/basic">
                </Link> */}
                  <ExampleCard image={img4} display="grid" minHeight="max-content" p={0.5} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}
function ECard ( image, name, count, ...rest ) {
  return(
<MKBox>
  <MKBox
    bgColor="white"
    borderRadius="xl"
    shadow="lg"
    minHeight="10rem"
    sx={{
      overflow: "hidden",
      transform: "perspective(999px) rotateX(0deg) translate3d(0, 0, 0)",
      transformOrigin: "50% 0",
      backfaceVisibility: "hidden",
      willChange: "transform, box-shadow",
      transition: "transform 200ms ease-out",

      "&:hover": {
        transform: "perspective(999px) rotateX(7deg) translate3d(0px, -4px, 5px)",
      },
    }}
    {...rest}
  >
    
    <MKBox 
    component="img" src={image} alt={name} width="100%" my="auto"
   
     />
  </MKBox>
  {name || count > 0 ? (
    <MKBox mt={1} ml={1} lineHeight={1}>
      {name && (
        <MKTypography variant="h6" fontWeight="bold">
          {name}
        </MKTypography>
      )}
      {count > 0 && (
        <MKTypography variant="button" fontWeight="regular" color="secondary">
          {count} {count === 1 ? "Example" : "Examples"}
        </MKTypography>
      )}
    </MKBox>
  ) : null}
</MKBox>
)
}

export default AuthPages;
