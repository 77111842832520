/* eslint-disable prettier/prettier */
/* eslint-disable arrow-body-style */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
// import routes from "routes";
import routes from "routes_wtp";
import footerRoutes from "footer.routes";

function Privacy() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        // action={{
        //   type: "external",
        //   route: "https://www.creative-tim.com/product/material-kit-pro-react",
        //   label: "buy now",
        //   color: "dark",
        // }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Privacy & Policy
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Last modified: May 27 2021
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Introduction
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  The E.S.T.E Group (“us”, “we”, or “our”) operates the  {" "}
                    <MKTypography
                      variant="body2"
                      color="dark"
                      component="a"
                      href="/"
                      target="_blank"
                      rel="noreferrer"
                      sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                    >
                      website
                    </MKTypography>
                    / #WTP@ mobile app (the “Service”). 
                    <br />
                    <br />
                    This page informs you of our policies regarding the collection, 
                    use and disclosure of Personal Information when you use our Service.
                    We will not use or share your information with anyone except as described in this Privacy Policy.
                    We use your Personal Information for providing and improving the Service. 
                    
                    <br />
                    <br />
                    By using the Service, you agree to the collection and use of information in accordance with this policy. 
                    Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible at{" "}
                    <MKTypography
                      variant="body2"
                      color="dark"
                      component="a"
                      href="/pages/support/terms"
                      target="_blank"
                      rel="noreferrer"
                      sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                    >
                      www.wtpa.club
                    </MKTypography>
                    .
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                  Information Collection And Use
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you.
                    Personally identifiable information may include, but is not limited to, your email address, name, phone number, postal address, other information (“Personal Information”).
                    We collect this information for the purpose of providing the Service, identifying and communicating with you, responding to your requests/inquiries, servicing your purchase orders, and improving our services.
                      <br/><br/>
                  </MKTypography>
                  {/* <MKTypography variant="body2" color="text">
                    You must not:
                  </MKTypography> */}
                  {/* <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Republish material from Creative Tim
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Sell, rent or sub-license material from Creative Tim
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Reproduce, duplicate or copy material from Creative Tim
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Redistribute content from Creative Tim
                      </MKTypography>
                    </MKBox>
                  </MKBox> */}
                  {/* <MKTypography variant="body2" color="text">
                    This Agreement shall begin on the date hereof. Our Terms and Conditions were
                    created with the help of the{" "}
                    <MKTypography
                      variant="body2"
                      color="text"
                      component="a"
                      href="https://www.creative-tim.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms And Conditions Generator
                    </MKTypography>{" "}
                    and the{" "}
                    <MKTypography
                      variant="body2"
                      color="dark"
                      component="a"
                      href="https://www.creative-tim.com"
                      target="_blank"
                      rel="noreferrer"
                      sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                    >
                      Privacy Policy Generator
                    </MKTypography>
                    .
                  </MKTypography> */}
                  <MKTypography variant="h5" mt={6} mb={3}>
                  Log Data
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  We may also collect information that your browser sends whenever you visit our Service (“Log Data”). 
                  This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages and other statistics.
                    <br/><br/>
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                  Cookies
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Cookies are files with a small amount of data, which may include an anonymous unique identifier. 
                    Cookies are sent to your browser from a web site and transferred to your device. 
                    We use cookies to collect information in order to improve our services for you.
                    If you do not accept cookies, you may not be able to use some features of our Service and we recommend that you leave them turned on.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                  Service Providers
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  We may employ third party companies and individuals to facilitate our Service, 
                  to provide the Service on our behalf, to perform Service-related services and/or to assist us in analyzing how our Service is used.
                  These third parties have access to your Personal Information only to perform specific tasks on our behalf 
                  and are obligated not to disclose or use your information for any other purpose.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                  Security
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  The security of your Personal Information is important to us, and we strive to implement and maintain reasonable, 
                  commercially acceptable security procedures and practices appropriate to the nature of the information we store, 
                  in order to protect it from unauthorized access, destruction, use, modification, or disclosure.
                  However, please be aware that no method of transmission over the internet, 
                  or method of electronic storage is 100% secure and we are unable to guarantee the absolute security of the Personal Information we have collected from you.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                  Links To Other Sites
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  Our Service may contain links to other sites that are not operated by us. 
                  If you click on a third party link, you will be directed to that third party’s site. 
                  We strongly advise you to review the Privacy Policy of every site you visit.
                  We have no control over, and assume no responsibility for the content, 
                  privacy policies or practices of any third party sites or services.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                  Children’s Privacy
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  Only persons age 18 or older have permission to access our Service. 
                  Our Service does not address anyone under the age of 13 (“Children”).
                  We do not knowingly collect personally identifiable information from children under 13. 
                  If you are a parent or guardian and you learn that your Children have provided us with Personal Information, please contact us. 
                  If we become aware that we have collected Personal Information from a child under age 13 without verification of parental consent, we take steps to remove that information from our servers.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                  Changes To This Privacy Policy
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                  This Privacy Policy is effective as of May 27, 2021 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.
                  We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. 
                  Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
                  If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                    Contact Us
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                  If you have any questions about this Privacy Policy, please contact us.
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;
