/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
// @mui material components
import { useRef,useEffect,useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

import moment from 'moment'

// Material Kit 2 PRO React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function Counters() {

  const members = useRef(550)
  const created = useRef(1000)
  const attended = useRef(4000)

  useEffect(()=>{
    // loadStats()
  })


  const updateMembers = async() => {
    const getObj = localStorage.getItem('members')
    if(getObj !== undefined && getObj !== null){
      const obj = JSON.parse(getObj)
      const count = obj.number
      const date = obj?.date
      const updateDate = moment(date).add(2,'weeks').format()
      const today = moment().format()
      if(today > updateDate){
        const newCount = count+50
        const newObj = {
          number:newCount,
          date:today,
        }
        localStorage.setItem('members', JSON.stringify(newObj))
        members.current = newCount
      }

    }else{
      const obj = {
        number:members.current,
        date:moment().format()
      }
      localStorage.setItem('members', JSON.stringify(obj))
    }

  }


  const updateCreated = async() => {
    const getObj = localStorage.getItem('created')
    if(getObj !== undefined && getObj !== null){
      const obj = JSON.parse(getObj)
      const count = obj?.number
      const date = obj?.date
      const updateDate = moment(date).add(1,'weeks').format()
      const today = moment().format()
      if(today > updateDate){
        const disCount = (count/10)/2
        const newCount = count+disCount.toFixed()
        const newObj = {
          number:newCount,
          date:today,
        }
        localStorage.setItem('created', JSON.stringify(newObj))
        created.current = newCount
      }
            
    }else{
      const obj = {
        number:created.current,
        date:moment().format()
      }
      localStorage.setItem('created', JSON.stringify(obj))
    }
  }



  const updateAttended = async() => {
    const getObj = localStorage.getItem('attended')
    if(getObj !== undefined && getObj !== null){
      const obj = JSON.parse(getObj)
      const count = obj?.number
      const date = obj?.date
      const updateDate = moment(date).add(1,'weeks').format()
      const today = moment().format()
      if(today > updateDate){
        const disCount = (count/10)/2
        const newCount = count+disCount.toFixed()
        const newObj = {
          number:newCount,
          date:today,
        }
        localStorage.setItem('attended', JSON.stringify(newObj))
        attended.current = newCount
      }
            
    }else{
      const obj = {
        number:attended.current,
        date:moment().format()
      }
      localStorage.setItem('attended', JSON.stringify(obj))
    }

  }

  const loadStats = () => {
    updateMembers()
    updateCreated()
    updateAttended()
  }


  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={members.current}
              suffix="+"
              title="Members"
              description="Increasing community of app users. The number grows everyday."
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              count={created.current}
              suffix="+"
              title="Events Created"
              description="No event is too small to add, even if it's just a kick back or pizza party."
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={attended.current}
              title="Attended"
              description="Events can be public (anyone can attend) or private (invite only)."
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
