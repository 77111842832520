/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import RotatingCard from "examples/Cards/RotatingCard";

// Material Kit 2 PRO React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";


import infoImage from "assets/images/wtp/1540494725757.jpeg";
import infoImage2 from "assets/images/wtp/1540493096627.jpeg";

function Information() {
  const titl = `Bored on the Weekends? \nWondering #WTP@`
  const desc = `\n\nTo find a party on campus is very easy. 
  You can now search by your College, City or Zip and find events on campus or off campus.`


  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="House/Dorm"
                    description="Add an event and invite your friends or add a public event and make new friends."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Clubs"
                    description="Looking for a new turn-up spot? View all the nightclubs in your area by tapping the Club tab."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Bars/Pubs"
                    description="Trying to find a chill location to enjoy a brewski or two? View all bars in your area by tapping the Bar tab"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="Under 21"
                    description="Seeing that most college students are U-21, we can't forget about you. Tap the U-21 tab to view under 21 clubs in your area."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <RotatingCard>
              <MKBox
                display="flex"
                justifyContent="center"
                alignContent="center"
                borderRadius="lg"
                coloredShadow="secondary"
                width="100%"
                position="relative"
                zIndex={2}
                sx={{
                  backfaceVisibility: "hidden",
                  // transform: "rotateY(180deg)",
                }}
              >
            <CenteredBlogCard
              image={infoImage}
              title={titl}
              description={desc}
              color="secondary"
              action={{
                type: "internal",
                route: "/pages/support/help-center",
                color: "info",
                label: "find out more",
              }}
            />
            </MKBox>
            <MKBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="lg"
              coloredShadow='info'
              position="absolute"
              width="100%"
              height="100%"
              top={0}
              left={0}
              zIndex={5}
              sx={{
                backfaceVisibility: "hidden",
                transform: "rotateY(180deg)",
              }}
            >
            <CenteredBlogCard
              image={infoImage2}
              title={titl}
              description={desc}
              color="secondary"
              action={{
                type: "internal",
                route: "/pages/support/help-center",
                color: "info",
                label: "find out more",
              }}
            />
            </MKBox>
            </RotatingCard>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
