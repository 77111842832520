/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// HelpCenter page components
import FaqCollapse from "pages/Support/HelpCenter/components/FaqCollapse";

import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";
import bgImage1 from "assets/images/examples/color1.jpg";

import H2PrivPar from "./H2PrivateParty";
import H2MusGen from "./H2MusicGenre";
import H2DarMod from "./H2DarkMode";
import H2ProPic from "./H2ProfilePic";
import H2DelAcc from "./H2DeleteAcc";
import H2PubPar from "./H2PublicParty";
import H2AddCon from "./H2AddContact";
import H2Unseac from "./H2UnSearchable";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6} my={6}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Frequently Asked Questions
            </MKTypography>
            <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
                <>
                <br/><br/>
                </>
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={10}>
            <FaqCollapse
              title="How do I host a private party?"
              open={collapse === 1}
              onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
            >       
            <H2PrivPar/>
            <br />
            </FaqCollapse>
            <FaqCollapse
              title="How can i find party by musical genre?"
              open={collapse === 2}
              onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
            >
            <H2MusGen/>
            <br />
            </FaqCollapse>
            <FaqCollapse
              title="How to turn on dark mode."
              open={collapse === 3}
              onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
            >
            <H2DarMod />
            <br />
            </FaqCollapse>
            <FaqCollapse
              title="How do I add/change my profile picture?"
              open={collapse === 4}
              onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
            >
              <H2ProPic />
              <br />
            </FaqCollapse>
            <FaqCollapse
              title="How do i delete my account?"
              open={collapse === 5}
              onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
            >
            <H2DelAcc />
            <br />
            </FaqCollapse>
            <FaqCollapse
              title="How do i add a public party?"
              open={collapse === 6}
              onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
            >
            <H2PubPar />
            <br />
            </FaqCollapse>
            <FaqCollapse
              title="How do i add a contact?"
              open={collapse === 7}
              onClick={() => (collapse === 7 ? setCollapse(false) : setCollapse(7))}
            >
            <H2AddCon />
            <br />
            </FaqCollapse>
            <FaqCollapse
              title="Can i make my name unsearchable?"
              open={collapse === 8}
              onClick={() => (collapse === 8 ? setCollapse(false) : setCollapse(8))}
            >
            <H2Unseac />
            <br />
            </FaqCollapse>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;
